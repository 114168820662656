import React, { useRef, useState } from "react"
import * as PropTypes from "prop-types"
import { useT } from "react-polyglot-hooks"
import styled from "styled-components"
import { BRANDS } from "helpers/constants"
import { sendEvent } from "scripts/GoogleAnalytics"
import { scrollToRef } from "helpers/scroll"
import { SectionContainer, TitleWrapper } from "components/styled/Grid"
import Typography from "components/styled/Typography"
import Link from "components/Link"
import Image from "next/image"
import { mq } from "helpers/styled"

const BrandsContainer = styled(SectionContainer)`
  @media ${mq("lg")} {
    padding-top: 50px;
  }
`

const BrandContainer = styled.a`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  background: white;
  border-radius: 6px;
  box-shadow: 0 2px 10px 0 ${({ theme }) => theme.colors.lightGray};
  padding: 5px 0;
  width: 95px;
  min-height: 65px;
  margin: 0 auto;
`

const BrandName = styled(Typography)`
  text-transform: capitalize;
  margin-top: 5px;
`

const Item = styled.div`
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 0.5s, opacity 0.5s;
  text-align: center;
  width: 33.3333%;

  .show-all & {
    height: 85px !important;
    opacity: 1 !important;
  }

  &:nth-child(-n + 9) {
    height: 85px;
    opacity: 1;
  }

  @media ${mq("md")} {
    width: 16.6666%;

    &:nth-child(-n + 12) {
      height: 85px;
      opacity: 1;
    }
  }

  @media ${mq("xl")} {
    width: 10%;

    && {
      height: 0;
      opacity: 0;
    }

    &:nth-child(-n + 10) {
      height: 85px;
      opacity: 1;
    }
  }
`

const ImageContainer = styled.div`
  height: 32px;
  width: 32px;
  margin: 0 auto;
`

const List = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  margin: 0 auto;
  max-width: 325px;

  @media ${mq("md")} {
    max-width: 670px;
  }

  @media ${mq("lg")} {
    max-width: 670px;
  }

  @media ${mq("xl")} {
    max-width: 1175px;
  }
`

const ShowMore = styled(Typography)`
  cursor: pointer;
  text-align: center;
`

export default function Brands({ page, title }) {
  const [showMore, setShowMore] = useState(false)
  const ref = useRef(null)
  const t = useT()

  const toggle = () => {
    sendEvent("Click brand", { event_category: page, event_label: showMore ? "collapse" : "expand" })
    if (showMore && window.scrollY > ref.current?.offsetTop) scrollToRef(ref, 100)
    setTimeout(() => setShowMore(!showMore), showMore ? 200 : 0)
  }

  const handleCLick = brand => {
    sendEvent("Click brand icon", { event_category: page, event_label: brand })
  }

  return (
    <BrandsContainer ref={ref}>
      {title && (
        <TitleWrapper>
          <Typography variant="title2" weight="bold" center space>
            {t(title)}
          </Typography>
        </TitleWrapper>
      )}
      <List className={showMore ? "show-all" : ""}>
        {BRANDS.map(brand => (
          <Item key={brand}>
            <Link page="brand" params={{ brand }} prefetch={false}>
              <BrandContainer onClick={() => handleCLick(brand)}>
                <ImageContainer>
                  <Image
                    height={32}
                    width={32}
                    alt={t("landings.brands.image_alt", { brand })}
                    src={`/f_svg/static/car_brand_logo/svg/${brand}`}
                  />
                </ImageContainer>
                <BrandName color="silver">{brand}</BrandName>
              </BrandContainer>
            </Link>
          </Item>
        ))}
      </List>
      <ShowMore variant="small" weight="light" color="deepOrange" onClick={toggle}>
        {`${t(`common.view_${showMore ? "less" : "more"}`)} ${t("common.brands")}`}
      </ShowMore>
    </BrandsContainer>
  )
}

Brands.defaultProps = {
  title: null
}

Brands.propTypes = {
  page: PropTypes.string.isRequired,
  title: PropTypes.string
}
